<template>
  <!-- 表格 -->
  <div class="tw-container m-0">
    <DataTable
      class="p-datatable-sm"
      :scrollHeight="`${scrollHeight}px`"
      :value="purchaseRecord"
      :loading="dataTabelLoading"
      :rowHover="true"
      :paginator="true"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      :rows="20"
      :rowsPerPageOptions="[20, 50, 100]"
      :scrollable="true"
      currentPageReportTemplate="從第 {first} 筆到 第 {last} 筆，總共 {totalRecords} 筆"
      v-model:filters="searchGlobal"
      filterDisplay="row"
      stateStorage="local"
      stateKey="dt-state-orderGood-local"
      rowGroupMode="subheader"
      groupRowsBy="style"
      :expandableRowGroups="true"
      v-model:expandedRowGroups="expandedRowGroups"
      sortMode="single"
      sortField="style"
    >
      <template #header>
        <!-- <input
            class="form-control"
            v-model="searchGlobal['global'].value"
            placeholder="表格搜尋"
          /> -->
        <button class="tw-btn tw-btn-success me-2 mb-2" @click="expandAll">
          ＋展開全部</button
        ><button class="tw-btn tw-btn-success mb-2" @click="collapseAll">
          －收合全部
        </button>
      </template>
      <!-- 叫貨單編號 -->
      <Column
        field="purchaseOrderHeaderId"
        header="叫貨單編號"
        sortable
      ></Column>
      <!-- 成本 -->
      <Column field="cost" header="成本" sortable></Column>
      <!-- 金額 -->
      <Column field="price" header="金額" sortable></Column>
      <!-- 供應商 -->
      <Column
        field="supplier.name"
        header="供應商"
        style="min-width: 100px; max-width: 100px"
        sortable
      ></Column>
      <!-- 叫貨人員 -->
      <Column
        field="creator"
        header="叫貨人員"
        style="min-width: 150px; max-width: 150px"
        sortable
      ></Column>
      <!-- 叫貨時間 -->
      <Column
        field="headerCreateTime"
        header="叫貨時間"
        style="min-width: 200px; max-width: 200px"
        sortable
      >
      </Column>
      <!-- 數量 -->
      <Column field="quantity" header="數量" sortable></Column>
      <!-- 狀態 -->
      <Column field="" header="狀態" style="min-width: 100px; max-width: 100px">
        <template #body="{ data }">
          <p>到貨: {{ data.stockInQuantity }}</p>
          <p>未到: {{ Math.max(data.quantity - data.stockInQuantity, 0) }}</p>
        </template>
      </Column>
      <!-- 操作 -->
      <Column field="" header="操作" style="min-width: 200px; max-width: 200px">
        <template #body="{ data }">
          <div class="row">
            <div class="col-12 p-0 d-flex mb-2">
              <div class="me-2">
                <button
                  class="tw-btn tw-btn-success"
                  @click="stockInBodies('single', data, false)"
                >
                  全到
                </button>
              </div>
              <div class="me-2">
                <button
                  class="tw-btn tw-btn-success"
                  @click="stockInBodies('single', data, true)"
                >
                  全到並配單
                </button>
              </div>
            </div>
            <div class="col-12 p-0 d-flex">
              <div class="me-2">
                <button
                  class="tw-btn tw-btn-success"
                  @click="showModal('editBody', data)"
                >
                  編輯
                </button>
              </div>
              <!-- <div class="me-2">
                <button
                  class="tw-btn tw-btn-danger"
                  @click="showModal('singleDel', data)"
                >
                  刪除
                </button>
              </div> -->
            </div>
          </div>
        </template>
      </Column>
      <template #groupheader="slotProps">
        <span class="fw-bolder text-primary">{{ slotProps.data.style }}</span>
      </template>
      <template #empty>
        <p class="text-center fw-bolder text-primary">
          目前尚未有任何資料 0..0
        </p>
      </template>
    </DataTable>
  </div>
  <!-- 編輯單身的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="editBodyModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">編輯明細</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="alert alert-primary">
            <p class="text-black fw-bolder tw-text-size16">
              商品款式: {{ editbody.style }}
            </p>
          </div>
          <!-- 成本 -->
          <div class="form-floating mb-3">
            <input
              onwheel="this.blur()"
              type="number"
              class="form-control"
              id="BodyCost"
              placeholder="成本"
              min="0"
              v-model="editbody.cost"
            />
            <label for="BodyCost">成本</label>
          </div>
          <!-- 已到貨數量 -->
          <div class="form-floating mb-3">
            <input
              onwheel="this.blur()"
              type="number"
              class="form-control"
              id="BodyArrivalQuantity"
              placeholder="已到貨數量"
              v-model="editbody.stockInQuantity"
              pattern="[0-9]*"
              oninput="this.value=this.value.replace(/\D/g,'')"
            />
            <label for="BodyArrivalQuantity">已到貨數量</label>
          </div>
          <!-- 叫貨數量 -->
          <div class="form-floating mb-3">
            <input
              onwheel="this.blur()"
              type="number"
              class="form-control"
              id="BodyOrderGoods"
              placeholder="叫貨數量"
              v-model="editbody.quantity"
              pattern="[0-9]*"
              oninput="this.value=this.value.replace(/\D/g,'')"
            />
            <label for="BodyOrderGoods">叫貨數量</label>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="updateBody(true)"
          >
            確認並配單
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="updateBody(false)"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 空的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref=""
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">Title</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">...</div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button type="button" class="tw-btn tw-btn-success" @click="">
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// dataTable
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';     //optional for column grouping
import Row from 'primevue/row';                     //optional for row
import { FilterMatchMode } from 'primevue/api';

export default {
  components: {DataTable, Column, ColumnGroup, Row},
  data() {
    return {
      // modal
      editBodyModal: {},
      // data
      serverToken: '',
      storeId: 0,
      merchandiseId: null,
      purchaseRecord: [],
      // dataTable
      scrollHeight: 0,
      dataTabelLoading: false,
      searchGlobal: {
        global: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS
        }
      },
      expandedRowGroups: [],
      styleGroups: [],
      // 編輯單身資料
      editbody: {
        id: 0,
        cost: 0,
        style: '',
        quantity: 0,
        stockInQuantity: 0,
      }
    }
  },
  created() {
    this.initialization()
    this.getPurchaseOrderHeaders()
  },
  mounted() {
    this.createModals(['editBodyModal'])
  },
  unmounted() {
    // 將 dataTable 的紀錄清除
    localStorage.removeItem('dt-state-orderGood-local')
  },
  methods: {
    // 初始化
    initialization() {
      this.serverToken = this.$methods.getCookie('serverToken')
      this.storeId = this.$route.params.storeId
      // 紀錄 dataTabel 高度
      this.scrollHeight = window.innerHeight - 210
      // 將 dataTable 的紀錄清除
      localStorage.removeItem('dt-state-orderGood-local')
    },
    showModal(status, item) {
      if (status === 'editBody') {
        // 編輯單身
        this.editbody = {
          id: item.id,
          cost: item.cost,
          style: item.style,
          quantity: item.quantity,
          stockInQuantity: item.stockInQuantity,
        }
        this.editBodyModal.show()
      }
    },
    // 取得叫貨單資料
    getPurchaseOrderHeaders() {
      const vm = this
      const getPurchaseOrderHeadersApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      this.merchandiseId = this.$route.query.merchandiseId ? this.$route.query.merchandiseId : null
      const data = [
        {
          type: 16,
          ids: [this.merchandiseId],
          methods: '{getPurchaseOrderHeaders{getCreator{getUser},getSupplier,getPurchaseOrderBodies{getStock{getMerchandiseStyles}}}}'
        }
      ]
      $.ajax({
        type: 'POST',
        async: true,
        url: getPurchaseOrderHeadersApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const purchaseOrderHeaders = res.data[0].objects[0].purchaseOrderHeaders
            vm.purchaseOrderMap(purchaseOrderHeaders)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 整理叫貨資料
    purchaseOrderMap(purchaseOrderHeaders) {
      this.purchaseRecord = []
      this.styleGroups = []
      const styleMap = new Map()
      purchaseOrderHeaders.forEach(item => {
        item.purchaseOrderBodies.forEach(body => {
          console.log(body.stock.merchandiseId, this.merchandiseId)
          if (body.stock.merchandiseId === parseInt(this.merchandiseId)) {
            console.log(body)
            // 處理商品款式
            let merchandiseStyle = []
            body.stock.merchandiseStyles.forEach(style => {
              merchandiseStyle.push(style.name)
            })
            body.style = merchandiseStyle.toString()
            // 紀錄供應商
            body.supplier = null
            if (item.supplierId) body.supplier = item.supplier
            // 紀錄單頭建立時間
            body.headerCreateTime = this.$methods.moment(item.createTime).format('YYYY-MM-DD HH:mm:ss')
            // 紀錄運費
            body.shippingCost = item.shippingCost ? item.shippingCost : 0
            // 紀錄叫貨人員
            body.creator = item.creator.user.name
            // 金額
            body.price = body.quantity * body.cost
            if (!styleMap.has(merchandiseStyle.toString())) {
              styleMap.set(merchandiseStyle.toString(), [body])
            } else {
              styleMap.get(merchandiseStyle.toString()).push(body)
            }
            this.purchaseRecord.push(body)
          }
        })
      })
      for (const [key] of styleMap) {
        this.styleGroups.push(key)
      }
    },
    // 展開全部
    expandAll() {
      this.expandedRowGroups = this.styleGroups;
    },
    // 收合全部
    collapseAll() {
      this.expandedRowGroups = []
    },
    // 編輯單身
    updateBody(allocate) {
      const vm = this;
      const updateBodyApi = `${process.env.VUE_APP_API}/purchaseOrder/updateBody`;
      const header = {
        authorization: this.serverToken
      };
      const data = [{
        id: this.editbody.id,
        cost: this.editbody.cost ? this.editbody.cost : 0,
        quantity: this.editbody.quantity ? this.editbody.quantity : 0,
        stockInQuantity: this.editbody.stockInQuantity ? this.editbody.stockInQuantity : 0,
        allocate: allocate,
      }]
      $.ajax({
        type: "PUT",
        async: true,
        url: updateBodyApi,
        headers: header,
        data: JSON.stringify(data),
        contentType: 'application/json',
        success: function (res) {
          console.log(res);
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            vm.getPurchaseOrderHeaders()
            vm.editBodyModal.hide()
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      });
    },
    // 入庫
    stockInBodies(status, item, allocate) {
      console.log(item)
      const vm = this;
      const stockInBodiesApi = `${process.env.VUE_APP_API}/purchaseOrder/updateBody`;
      const header = {
        authorization: this.serverToken
      };
      let data = []
      if (status === 'all') {
        // 確定入庫按鈕
        this.purchaseOrderList.forEach(item => {
          item.purchaseOrderBodies.forEach(body => {
            if (body.warehousingQuantity !== 0) {
              data.push({
                id: body.id,
                stockInQuantity: body.warehousingQuantity
              })
            }
          })
        })
        if (data.length === 0) return this.SweetAlert('other', '您尚未輸入任何入庫數量')
      } else {
        // 單身內的全到
        if ((item.quantity - item.stockInQuantity) > 0) {
          data.push({
            id: item.id,
            stockInQuantity: 'max',
            allocate: allocate
          })
        }
        if (data.length === 0) return this.SweetAlert('other', '此明細已完全到貨嘍')
      }
      this.$methods.switchLoading('show')
      $.ajax({
        type: "PUT",
        async: true,
        url: stockInBodiesApi,
        headers: header,
        data: JSON.stringify(data),
        contentType: 'application/json',
        success: function (res) {
          console.log(res);
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            if (status === 'all') vm.advancedSearch()
            else item.stockInQuantity = item.quantity
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      });
    },
  },
}
</script>